import React from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircle, MagnifyingGlass, Package, Truck } from 'phosphor-react';

import { functionMapEventsToTimeline, determineCurrentStatus } from './utils';

import * as S from './styles';

const status = ['Pedido realizado', 'Enviado', 'Em trânsito', 'Entregue'];

const events = [
  {
    created_at: new Date('2023-11-10T18:45:46.000Z'),
    description: 'Objeto entregue ao destinatário',
    unity_type: 'Unidade de Distribuição',
    unity_code: '04081971',
    city: 'SAO PAULO',
    uf: 'SP',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-11-10T16:51:24.000Z'),
    description: 'Objeto saiu para entrega ao destinatário',
    unity_type: 'Unidade de Distribuição',
    unity_code: undefined,
    city: 'SAO PAULO',
    uf: 'SP',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-11-10T16:48:36.000Z'),
    description: 'Objeto devolvido aos Correios',
    unity_type: 'Unidade de Distribuição',
    unity_code: '04081971',
    city: 'SAO PAULO',
    uf: 'SP',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-11-09T23:53:13.000Z'),
    description: 'Objeto entregue ao destinatário',
    unity_type: 'Unidade de Distribuição',
    unity_code: '04081971',
    city: 'SAO PAULO',
    uf: 'SP',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-11-09T16:48:28.000Z'),
    description: 'Objeto saiu para entrega ao destinatário',
    unity_type: 'Unidade de Distribuição',
    unity_code: undefined,
    city: 'SAO PAULO',
    uf: 'SP',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-11-07T05:00:04.000Z'),
    description: 'Objeto em transferência - por favor aguarde',
    unity_type: 'Unidade de Tratamento',
    unity_code: '04293970',
    city: 'SAO PAULO',
    uf: 'SP',
    unity_destination_type: 'Unidade de Distribuição',
    unity_destination_city: 'SAO PAULO',
    unity_destination_uf: 'SP',
  },
  {
    created_at: new Date('2023-11-06T14:31:15.000Z'),
    description: 'Objeto em transferência - por favor aguarde',
    unity_type: 'Unidade de Tratamento',
    unity_code: '07750974',
    city: 'CAJAMAR',
    uf: 'SP',
    unity_destination_type: 'Unidade de Tratamento',
    unity_destination_city: 'SAO PAULO',
    unity_destination_uf: 'SP',
  },
  {
    created_at: new Date('2023-11-04T15:16:12.000Z'),
    description: 'Objeto em transferência - por favor aguarde',
    unity_type: 'Unidade de Logística Integrada',
    unity_code: '81010971',
    city: 'CURITIBA',
    uf: 'PR',
    unity_destination_type: 'Unidade de Tratamento',
    unity_destination_city: 'CAJAMAR',
    unity_destination_uf: 'SP',
  },
  {
    created_at: new Date('2023-11-04T15:16:11.000Z'),
    description: 'Saída do Centro Internacional',
    unity_type: 'Unidade de Logística Integrada',
    unity_code: '81010971',
    city: 'CURITIBA',
    uf: 'PR',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-11-01T16:08:02.000Z'),
    description: 'Objeto recebido pelos Correios do Brasil',
    unity_type: 'Unidade de Logística Integrada',
    unity_code: '81010971',
    city: 'CURITIBA',
    uf: 'PR',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-10-28T15:33:44.000Z'),
    description: 'Objeto postado',
    unity_type: 'País',
    unity_code: '00156000',
    city: undefined,
    uf: undefined,
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
  {
    created_at: new Date('2023-10-28T11:30:26.000Z'),
    description: 'Informações eletrônicas enviadas para análise da autoridade aduaneira',
    unity_type: 'Unidade de Logística Integrada',
    unity_code: '81010971',
    city: 'CURITIBA',
    uf: 'PR',
    unity_destination_type: undefined,
    unity_destination_city: undefined,
    unity_destination_uf: undefined,
  },
];

// DATA steps
const stepss = [
  {
    label: 'Entregue',
    description: 'Seu pacote foi entregue',
    icon: <CheckCircle size={22} color="#2F9E44" />,
    city: 'Fortaleza, CE',
    date: '08/12/2023 - 17:31',
  },
  {
    label: 'Em trânsito',
    description: 'Seu pacote está em trânsito',
    icon: <Truck size={22} color="#364FC7" />,
    city: 'Fortaleza, CE',
    date: '08/12/2023 - 17:31',
  },
  {
    label: 'Enviado',
    description: 'Pacote postado',
    icon: <Package size={22} color="#364FC7" />,
    city: 'Fortaleza, CE',
    date: '08/12/2023 - 17:31',
  },
  {
    label: 'Pedido realizado',
    description: 'Preparando pacote',
    icon: <MagnifyingGlass size={22} color="#364FC7" />,
    city: 'Fortaleza, CE',
    date: '08/12/2023 - 17:31',
  },
];

const ENVIRONMENT = process.env.REACT_APP_STAGE;

interface ITrackingStatus {
  id: string;
  alias_id: string;
  fulfillment_tracking_id: string;
  provider_created_at: Date;
  description: string;
  unity_type: string;
  unity_code: string;
  city: string;
  uf: string;
  unity_destination_type?: string;
  unity_destination_city?: string;
  unity_destination_uf?: string;
  created_at: Date;
  updated_at: Date;
}

const Shipment: React.FC = () => {
  const { trackingCode } = useParams();
  const [trackingStatus, setTrackingStatus] = React.useState<ITrackingStatus[] | undefined>(
    undefined,
  );
  const [errorFetching, setErrorFetching] = React.useState<boolean>(false);

  const getTracking = React.useCallback(async () => {
    const URL = `https://rocklee-api.profitfy.me/${ENVIRONMENT}/api/v1/fulfillment/tracking/${trackingCode}`;

    const OPTIONS = {
      method: 'GET',
      headers: new Headers({ 'content-type': 'application/json; charset=utf-8' }),
    };

    try {
      const response = await fetch(URL, OPTIONS);
      const parsedResponse = await response.json();

      if (parsedResponse.status !== 'success') {
        throw new Error('Error fetching tracking code');
      }
      setTrackingStatus(parsedResponse.fulfillment_tracking_status);
    } catch (error) {
      setErrorFetching(true);
    }
  }, [trackingCode, setTrackingStatus, setErrorFetching]);

  const mappedStatus = functionMapEventsToTimeline(trackingStatus);
  const currentStatus = determineCurrentStatus(trackingStatus);

  React.useEffect(() => {
    getTracking();
  }, [getTracking]);

  console.log(trackingStatus, errorFetching);

  if (!trackingStatus && !errorFetching) {
    return <h2>Carregando...</h2>;
  }

  if (errorFetching) {
    return <h2>Houve um problema ao buscar o código de rastreio</h2>;
  }

  return (
    <S.Container>
      <S.ContainerStep>
        {status.map((label, i) => {
          return (
            <>
              <S.LineStep completed={i < currentStatus.step} />
              <S.StepWrapper>
                <S.Step completed={i < currentStatus.step} />
                <S.Text completed={i < currentStatus.step}>{label}</S.Text>
              </S.StepWrapper>
              <S.LineStep completed={i < currentStatus.step} />
            </>
          );
        })}
      </S.ContainerStep>
      <S.DetailWrapper>
        <S.Details>
          <S.SubTitle>Detalhes da remessa</S.SubTitle>

          <S.ShipmentDetailsWrapper>
            <S.VerticalDetailWrapper>
              {mappedStatus.map((step, i) => (
                <>
                  {step.label !== 'Entregue' && <S.VerticalLineStep />}
                  {step.label === 'Entregue' && i !== 0 && <S.VerticalLineStep />}
                  <S.VerticalStepWrapper>
                    <S.VerticalStep>{step.icon}</S.VerticalStep>
                  </S.VerticalStepWrapper>
                </>
              ))}
            </S.VerticalDetailWrapper>

            <S.VerticalInfoWrapper>
              {mappedStatus.map(step => (
                <S.TrakkingWrapper>
                  <S.TrakkingTitle>{step.description}</S.TrakkingTitle>
                  <S.TrakkingAddress>{step.city}</S.TrakkingAddress>
                  <S.TrakkingDate>{step.date}</S.TrakkingDate>
                </S.TrakkingWrapper>
              ))}
            </S.VerticalInfoWrapper>
          </S.ShipmentDetailsWrapper>
        </S.Details>
      </S.DetailWrapper>
    </S.Container>
  );
};

export default Shipment;
