import React from 'react';
import { format, parseISO } from 'date-fns';
import { CheckCircle, MagnifyingGlass, Package, Truck } from 'phosphor-react';

export function functionMapStatusToIcon(status: string): any {
  switch (status) {
    case 'Entregue':
      return <CheckCircle size={22} color="#2F9E44" />;
    case 'Em trânsito':
      return <Truck size={22} color="#364FC7" />;
    case 'Enviado':
      return <Package size={22} color="#364FC7" />;
    case 'Pedido realizado':
      return <MagnifyingGlass size={22} color="#364FC7" />;
    default:
      return <MagnifyingGlass size={22} color="#364FC7" />;
  }
}
interface IEvents {
  id?: string;
  alias_id?: string;
  fulfillment_tracking_id?: string;
  provider_created_at?: Date;
  description: string;
  unity_type?: string;
  unity_code?: string;
  city?: string;
  uf?: string;
  unity_destination_type?: string;
  unity_destination_city?: string;
  unity_destination_uf?: string;
  created_at: Date;
  updated_at: Date;
}

export function functionMapEventsToTimeline(events?: IEvents[]): {
  date: string;
  description: string;
  city: string;
  label: string;
  icon: any;
}[] {
  if (!events) return [];

  return events.map(event => {
    const hasCityAndUf = event.city && event.uf;
    let label = '';

    switch (event.description) {
      case 'Objeto entregue ao destinatário':
        label = 'Entregue';
        break;
      case 'Objeto saiu para entrega ao destinatário':
      case 'Objeto em transferência - por favor aguarde':
        label = 'Em trânsito';
        break;
      case 'Objeto postado':
        label = 'Enviado';
        break;
      case 'Informações eletrônicas enviadas para análise da autoridade aduaneira':
        label = 'Pedido realizado';
        break;
      default:
        label = 'Status desconhecido';
    }

    return {
      date: format(event.created_at, 'dd/MM/yyyy HH:mm'),
      description: event.description,
      city: hasCityAndUf ? `${event.city} - ${event.uf}` : '',
      label,
      icon: functionMapStatusToIcon(label),
    };
  });
}

export function determineCurrentStatus(events?: IEvents[]): { step: number; status: string } {
  if (!events || events.length === 0) {
    return {
      step: 0,
      status: 'Informações não disponíveis',
    };
  }

  const sortedEvents = events.sort((a, b) => {
    const dateA = a.created_at instanceof Date ? a.created_at : parseISO(a.created_at);
    const dateB = b.created_at instanceof Date ? b.created_at : parseISO(b.created_at);
    return dateB.getTime() - dateA.getTime();
  });

  const latestEventDescription = sortedEvents[0].description.toLowerCase();

  if (latestEventDescription.includes('entregue ao destinatário')) {
    return {
      step: 4,
      status: 'Entregue',
    };
  }
  if (latestEventDescription.includes('saiu para entrega')) {
    return {
      step: 3,
      status: 'Em trânsito',
    };
  }
  if (latestEventDescription.includes('em transferência')) {
    return {
      step: 3,
      status: 'Em trânsito',
    };
  }
  if (latestEventDescription.includes('postado')) {
    return {
      step: 2,
      status: 'Enviado',
    };
  }
  if (latestEventDescription.includes('informações eletrônicas enviadas')) {
    return {
      step: 1,
      status: 'Pedido realizado',
    };
  }

  return {
    step: 0,
    status: 'Status desconhecido',
  };
}
