import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from '@pages/Home';
import NotFound from '@pages/NotFound';
import Shipment from '@pages/Shipment';
import UrlShortener from '@pages/UrlShortener';
import theme from '@styles/themes/dark/theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/GlobalStyle.js';

const App: React.FC = () => {
  return (
    <div className="App" style={{ height: '100vh' }}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/shipment/:trackingCode" element={<Shipment />} />
            <Route path="/:urlShortenerId" element={<UrlShortener />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <GlobalStyle />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
