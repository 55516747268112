import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 180%;
`;

export const SubTitle = styled.span`
  color: #868e96;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 105%;
  margin-top: 8px;
`;

export const TrakkingTitle = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 105%;
`;

export const TrakkingAddress = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 105%;
  color: #495057;
`;

export const TrakkingDate = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 105%;
  text-transform: uppercase;
`;

export const Text = styled.span<{ completed?: boolean }>`
  position: absolute;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 100%;
  margin-top: 8px;
  top: 140px;

  ${({ completed }) =>
    completed &&
    `
    color: #2F9E44;
  `}
`;

export const ShipmentDetailsWrapper = styled.div`
  display: flex;
`;

export const ContainerStep = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
`;

export const DetailWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 62px;
`;

export const VerticalDetailWrapper = styled.div`
  width: 100%;
  max-width: 40px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  align-items: center;
`;

export const VerticalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-left: 17px;
  gap: 24px;
`;

export const TrakkingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 704px;
  border: 2px solid ${({ theme }) => theme.colors.gray[3]};
  border-radius: 8px;
  padding: 32px;
`;

export const StepWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VerticalStepWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Step = styled.div<{ completed: boolean }>`
  background: #e9ecef;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  ${({ completed }) =>
    completed &&
    `
    background: #2F9E44;
  `}
`;

export const VerticalStep = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edf2ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

export const LineStep = styled.div<{ completed: boolean }>`
  background: #e9ecef;
  width: 80px;
  height: 4px;

  ${({ completed }) =>
    completed &&
    `
    background: #2F9E44;
  `}
`;

export const VerticalLineStep = styled.div`
  background: #edf2ff;
  width: 4px;
  height: 32px;
`;

export const ProgressBar = styled.div``;
