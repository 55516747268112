/* eslint-disable no-await-in-loop */
import React from 'react';
import { useParams } from 'react-router-dom';

const ENVIRONMENT = process.env.REACT_APP_STAGE;

const UrlShortener: React.FC = () => {
  const { urlShortenerId } = useParams();

  const redirect = React.useCallback(async () => {
    const URL = `https://rocklee-api.profitfy.me/${ENVIRONMENT}/api/v1/workflow-events/${urlShortenerId}/click`;

    const OPTIONS = {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json; charset=utf-8' }),
    };

    let retries = 0;

    while (retries < 3) {
      try {
        const response = await fetch(URL, OPTIONS);

        const parsedResponse = await response.json();

        if (parsedResponse.status === 'success') {
          const urlToRedirect = parsedResponse.full_url;

          window.location.href = urlToRedirect;
        }
      } catch (error) {
        console.log(error);
      }

      retries += 1;
    }
  }, [urlShortenerId]);

  React.useEffect(() => {
    redirect();
  }, [redirect]);

  return <div />;
};

export default UrlShortener;
