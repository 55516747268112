import React from 'react';

const Home: React.FC = () => {
  // React.useEffect(() => {
  //   window.location.href = 'https://profitfy.me';
  // }, []);

  return <div />;
};

export default Home;
