import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    font-size: 16px;
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;

    @media only screen and (max-width: 1366px) {
      font-size: 14px;
    }
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    input[data-autocompleted] {
        background-color: transparent !important;
    }
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    margin: 0;
    padding: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fonts.heading};
  }

  span, p, small {
    font-family: ${props => props.theme.fonts.body};
  }

  span {
    font-size: 1rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  small {
    font-size: 0.875rem;
  }

  #beamerSelector {
	  display: none;
  }

  .intercom-lightweight-app {
    div {
      bottom: 36px;
    }
  }

  .react-flow__edgeupdater {
    cursor:  grab !important;
  }

  em-emoji-picker{
    height: 336px;
  }
`;
